import React, { useState } from "react";
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  useFormik,
  FormikProvider,
} from "formik";
import * as Yup from "yup";
import { Button } from "../../Components/ui/button";
import { Combobox } from "../../Components/ui/combobox";
import { NumericFormat } from "react-number-format";
import { parseMoney } from "../../Lib/utils";
import { AutoComplete } from "../../Components/ui/comboInput";
import { useDispatch, useSelector } from "react-redux";
import {
  updateAvailableRiders,
  updateDriverLocation,
} from "../../Lib/slices/locationSlice";
import {
  useCreateOrderMutation,
  useLazyGetNearestRiderQuery,
} from "./DashboardApi";
import toast from "react-hot-toast";
import { useUpdateDriverStatus } from "../../Lib/hooks";

const validationSchema = Yup.object().shape({
  customerName: Yup.string().required("Please Enter Customer Name"),
  customerPhoneNumber: Yup.string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(11, "Must be exactly 11 digits")
    .max(11, "Must be exactly 11 digits")
    .required(" Enter Customer Phonenumber"),
  deliveryLocation: Yup.string().required("Please Enter Delivery Location"),
  pickupLocation: Yup.object().required("Please Enter Pickup Location"),
  orderAmount: Yup.number()
    .min(1, "Amount must be greater than 0")
    .typeError("You must specify a number")
    .required("Order Amount is required"),
  // serviceFee: Yup.number()
  //   .min(1, "Amount must be greater than 0")
  //   .typeError("You must specify a number")
  //   .required("Order Amount is required"),
  riderPercentage: Yup.number()
    .typeError("You must specify a number")
    .required("Rider commission is required"),
  riderAmount: Yup.number()
    .min(1, "Amount must be greater than 0")
    .typeError("You must specify a number")
    .required("Rider Amount is required"),
  rider: Yup.string().required("Please select a Rider"),
  itemName: Yup.string().required("Please Enter Item Name"),
});

const initialValues = {
  customerName: "",
  customerPhoneNumber: "",
  deliveryLocation: "",
  pickupLocation: "",
  orderAmount: "",
  serviceFee: "",
  riderPercentage: 80,
  riderAmount: "",
  vendorAmount: "",
  rider: "",
  itemName: "",
  deliveryNote: "",
};
const AssignRider = () => {
  const [createNewOrder, { data, isLoading }] = useCreateOrderMutation();
  const { loading, drivers } = useUpdateDriverStatus();
  const dispatch = useDispatch();
  const { availableRiders, driverLocation } = useSelector(
    (state) => state?.location
  );
  const [getRider, { isLoading: getRiderLoading }] =
    useLazyGetNearestRiderQuery();
  const newNum = (num) => {
    const idd = num.slice(1);
    return `+234${idd}`;
  };
  const handCreateOrder = async (values) => {
    const body = {
      item: values?.itemName,
      amount: parseInt(values?.orderAmount),
      riderFee: parseInt(values?.riderAmount),
      customerName: values?.customerName,
      customerPhoneNumber: newNum(values?.customerPhoneNumber),
      deliveryAddress: values?.deliveryLocation,
      extraDeliveryNote: values?.deliveryNote ?? "",
      pickupLocation: values?.pickupLocation,
      riderId: values?.rider,
      vendorFee: parseInt(values?.vendorAmount) ?? "",
      serviceFee: parseInt(values?.serviceFee) ?? "",
    };
    try {
      const { data, isLoading } = await createNewOrder(body);
      if (data?.statusCode === 201) {
        toast.success(data?.message ?? "Order Created", { id: "orderGood" });
        resetForm();
        setFieldValue("deliveryLocation", "");
        setFieldValue("pickupLocation", "");
      }
    } catch (error) {
      console.log("createOrderError", error);
    }
  };

  const handRiderRefetch = async () => {
    try {
      const { data } = await getRider(driverLocation);
      if (data?.statusCode === 200) {
        console.log(data.data);
        dispatch(updateAvailableRiders(data?.data));
      }
    } catch (error) {}
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      await handCreateOrder(values);
    },
  });
  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    handleBlur,
    values,
    errors,
    touched,
    setValues,
    resetForm,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <div className='w-full bg-white rounded-md p-2'>
          <div className='mb-2'>
            <h3 className='text-lg font-medium text-left mb-2'>
              Create a new Order
            </h3>
          </div>
          <div className='md:flex gap-5 items-start justify-between mt-4'>
            <div className='flex flex-col gap-1 text-left w-full md:w-[50%]'>
              <label
                htmlFor='customerName'
                className='block text-xs font-medium text-gray-700'
              >
                Customer Name
              </label>
              <input
                id='customerName'
                className='focus:border-primaryGreen border border-[#C2C7D6] outline-none w-full rounded-md p-1 '
                value={values.customerName}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete='off'
              />
              {errors.customerName && touched.customerName && (
                <p className='text-xs text-red'>
                  <ErrorMessage name='customerName' />
                </p>
              )}
            </div>
            <div className='flex flex-col gap-1 text-left w-full md:w-[50%]'>
              <label
                htmlFor='customerPhoneNumber'
                className='block text-xs font-medium text-gray-700'
              >
                Customer Phone Number
              </label>
              <input
                id='customerPhoneNumber'
                className='focus:border-primaryGreen border border-[#C2C7D6] outline-none w-full rounded-md p-1 '
                value={values.customerPhoneNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete='off'
              />
              {errors.customerPhoneNumber && touched.customerPhoneNumber && (
                <p className='text-xs text-red'>
                  <ErrorMessage name='customerPhoneNumber' />
                </p>
              )}
            </div>
          </div>
          <div className='md:flex gap-5 items-start justify-between mt-3'>
            <div className='flex flex-col gap-1 text-left w-full md:w-[50%]'>
              <label
                htmlFor='deliveryLocation'
                className='block text-xs font-medium text-gray-700'
              >
                Delivery Location
              </label>
              <input
                id='deliveryLocation'
                className='focus:border-primaryGreen border border-[#C2C7D6] outline-none w-full rounded-md p-1 '
                value={values.deliveryLocation}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete='off'
              />
              {errors.deliveryLocation && touched.deliveryLocation && (
                <p className='text-xs text-red'>
                  <ErrorMessage name='deliveryLocation' />
                </p>
              )}
            </div>
            <div className='flex flex-col gap-1 text-left w-full md:w-[50%]'>
              <label
                htmlFor='pickupLocation'
                className='block text-xs font-medium text-gray-700'
              >
                Pickup Location
              </label>
              <AutoComplete
                id='pickupLocation'
                handleOnBlur={handleBlur}
                setFieldValue={setFieldValue}
                onValueChange={handleChange}
              />
              {errors.pickupLocation && touched.pickupLocation && (
                <p className='text-xs text-red'>
                  <ErrorMessage name='pickupLocation' />
                </p>
              )}
            </div>
          </div>
          <div className='md:flex gap-5 items-start justify-between mt-3'>
            <div className='flex flex-col gap-1 text-left w-full md:w-[50%]'>
              <label
                htmlFor='orderAmount'
                className='block text-xs font-medium text-gray-700'
              >
                Order Amount
              </label>
              <NumericFormat
                name='orderAmount'
                className='focus:border-primaryGreen border border-[#C2C7D6] outline-none w-full rounded-md  placeholder:italic placeholder:text-slate-400 placeholder:text-sm p-1'
                placeholder={parseMoney(0)}
                allowNegative={false}
                decimalScale={2}
                prefix={"₦"}
                thousandSeparator=','
                value={values.orderAmount}
                onValueChange={({ value }) => {
                  const commission = (values.riderPercentage / 100) * value;
                  setValues({
                    ...values,
                    orderAmount: value,
                    riderAmount: commission,
                  });
                }}
              />
              {errors.orderAmount && touched.orderAmount && (
                <p className='text-xs text-red'>
                  <ErrorMessage name='orderAmount' />
                </p>
              )}
            </div>
            <div className='flex flex-col gap-1 text-left w-full md:w-[50%]'>
              <label
                htmlFor='serviceFee'
                className='text-xs font-medium text-gray-700'
              >
                Service Fee
              </label>
              <NumericFormat
                name='serviceFee'
                className='focus:border-primaryGreen border border-[#C2C7D6] outline-none w-full rounded-md  placeholder:italic placeholder:text-slate-400 placeholder:text-sm p-1'
                placeholder={parseMoney(0)}
                allowNegative={false}
                decimalScale={2}
                prefix={"₦"}
                value={values.serviceFee}
                thousandSeparator=','
                onValueChange={({ value }) =>
                  setFieldValue("serviceFee", value)
                }
              />
            </div>
          </div>
          <div className='md:flex gap-5 items-start justify-between mt-3'>
            <div className='flex flex-col gap-1 text-left w-full md:w-[50%]'>
              <label
                htmlFor='riderPercentage'
                className='block text-xs font-medium text-gray-700'
              >
                Rider Commission
              </label>
              <NumericFormat
                name='riderPercentage'
                className='focus:border-primaryGreen border border-[#C2C7D6] outline-none w-full rounded-md  placeholder:italic placeholder:text-slate-400 placeholder:text-sm p-1'
                placeholder={"%0"}
                allowNegative={false}
                maxLength={100}
                decimalScale={2}
                prefix={"%"}
                thousandSeparator=','
                value={values.riderPercentage}
                onValueChange={({ value }) => {
                  const commission = (value / 100) * values.orderAmount;
                  setValues({
                    ...values,
                    riderPercentage: value,
                    riderAmount: commission,
                  });
                }}
              />
              {errors.riderPercentage && touched.riderPercentage && (
                <p className='text-xs text-red'>
                  <ErrorMessage name='riderPercentage' />
                </p>
              )}
            </div>
            <div className='flex flex-col gap-1 text-left w-full md:w-[50%]'>
              <label
                htmlFor='riderAmount'
                className='block text-xs font-medium text-gray-700'
              >
                Rider Amount
              </label>
              <NumericFormat
                name='riderAmount'
                className='focus:border-primaryGreen border border-[#C2C7D6] outline-none w-full rounded-md  placeholder:italic placeholder:text-slate-400 placeholder:text-sm p-1'
                placeholder={parseMoney(0)}
                allowNegative={false}
                decimalScale={2}
                prefix={"₦"}
                thousandSeparator=','
                value={values.riderAmount}
                onValueChange={({ value }) =>
                  setFieldValue("riderAmount", value)
                }
              />
              {errors.riderAmount && touched.riderAmount && (
                <p className='text-xs text-red'>
                  <ErrorMessage name='riderAmount' />
                </p>
              )}
            </div>
          </div>

          <div className='md:flex gap-5 items-start justify-between mt-3'>
            <div className='flex flex-col gap-1 text-left w-full md:w-[50%]'>
              <label
                htmlFor='vendorAmount'
                className='block text-xs font-medium text-gray-700'
              >
                Vendor Amount
              </label>
              <NumericFormat
                name='vendorAmount'
                className='focus:border-primaryGreen border border-[#C2C7D6] outline-none w-full rounded-md  placeholder:italic placeholder:text-slate-400 placeholder:text-sm p-1'
                placeholder={parseMoney(0)}
                allowNegative={false}
                decimalScale={2}
                prefix={"₦"}
                thousandSeparator=','
                value={values.vendorAmount}
                onValueChange={({ value }) =>
                  setFieldValue("vendorAmount", value)
                }
              />
              {/* {errors.riderAmount && touched.riderAmount && (
                <p className='text-xs text-red'>
                  <ErrorMessage name='riderAmount' />
                </p>
              )} */}
            </div>
            <div className='flex flex-col gap-1 text-left w-full md:w-[50%]'>
              <label
                htmlFor='itemName'
                className='text-xs font-medium text-gray-700'
              >
                Item Name
              </label>
              <input
                id='itemName'
                className='focus:border-primaryGreen border border-[#C2C7D6] outline-none w-full rounded-md p-1 '
                value={values.itemName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.itemName && touched.itemName && (
                <p className='text-xs text-red'>
                  <ErrorMessage name='itemName' />
                </p>
              )}
            </div>
          </div>
          <div className='flex flex-col gap-1 justify-between w-full mt-3'>
            <div className='flex justify-between items-center'>
              <label
                htmlFor='selectDriver'
                className='text-xs font-medium text-gray-700'
              >
                Rider
              </label>
              <button
                className='text-xs text-gray-700 italic'
                onClick={handRiderRefetch}
                type='button'
              >
                {getRiderLoading ? "..." : "Refetch Rider"}
              </button>
            </div>
            <Combobox
              frameworks={availableRiders}
              setValue={(val) => {
                setFieldValue("rider", val?.value);
                dispatch(
                  updateDriverLocation({
                    lat: val?.location?.latitude,
                    lng: val?.location?.longitude,
                  })
                );
              }}
              value={values.rider}
              placeholder='Rider'
            />
            {errors.rider && touched.rider && (
              <p className='text-xs text-red'>
                <ErrorMessage name='rider' />
              </p>
            )}
          </div>
          <div className='flex flex-col gap-1 justify-between mt-3'>
            <label
              htmlFor='deliveryNote'
              className='text-xs font-medium text-gray-700'
            >
              Delivery Notes{" "}
              <span className=' text-slate-400 text-xs ml-2 italic'>
                (Optional)
              </span>
            </label>
            <textarea
              id='deliveryNote'
              className='focus:border-primaryGreen outline-none min-h-[80px] w-full rounded-md border border-[#C2C7D6] px-2 py-2 text-sm'
              value={values.deliveryNote}
              onChange={handleChange}
              onBlur={handleBlur}
              name='deliveryNote'
            />
          </div>

          <div className='mt-6'>
            <Button disabled={isLoading} type='submit' className='w-full'>
              {isLoading ? "Creating Order..." : "Create Order"}
            </Button>
          </div>
          {/* {JSON.stringify(values, null, 2)} */}
        </div>
      </form>
    </FormikProvider>
  );
};

export default AssignRider;
