import React, { useState } from "react";
import { Link } from "react-router-dom";
import { images } from "../../Assets";
import { ErrorMessage, FormikProvider, useFormik } from "formik";
import { LoginValidationSchema } from "./AuthValidationSchema";
import { EyeNoneIcon, EyeOpenIcon } from "@radix-ui/react-icons";
import { useLoginMutation } from "./AuthApi";
import { getFromLocalStorage, saveToLocalStorage } from "../../Lib/utils";
import { useDispatch } from "react-redux";
import { updateLogin } from "../../Lib/slices/authSlice";
import Spinner from "../../Assets/spinner";

const Login = () => {
  const [login] = useLoginMutation();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const user = getFromLocalStorage("user");
  const initialValues = {
    email: user?.email || "",
    password: "",
  };

  const handleLogin = async (values) => {
    const dataValues = {
      email: values?.email,
      password: values?.password,
    };
    try {
      setSubmitLoading(true);
      const data = await login(dataValues).unwrap();
      if (data.statusCode === 200) {
        saveToLocalStorage("user", {
          email: data?.data?.email,
          name: data?.data?.firstName,
        });
        setSubmitLoading(false);
        dispatch(updateLogin(data.data));
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginValidationSchema,
    onSubmit: async (values) => {
      await handleLogin(values);
    },
  });

  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    formik;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <div className='h-[100vh]'>
          <div className='md:flex items-center h-full justify-between'>
            <div className='w-[45%] hidden md:block p-10'>
              <img
                src={images.officeSpace}
                className='w-full h-auto rounded-lg'
                alt='nownow rider'
              />
            </div>
            <div className='w-full md:w-[50%] px-10 text-center mb-10'>
              <div className='mb-10 flex justify-center'>
                <img
                  src={images.nownow}
                  className='w-[40%] h-auto'
                  alt='nownow rider'
                />
              </div>
              <p className='font-bold text-xl mt-10'>Sign In</p>
              <p className='font-medium text-md my-5 text-center mt-7 md:text-center'>
                {user?.name ? `Welcome back ${user?.name}` : ""}
              </p>
              <div className='mt-10'>
                <div className='flex flex-col text-left w-full md:w-[70%] mx-auto'>
                  <label htmlFor='email' className='font-medium mb-1 text-xs'>
                    Email
                  </label>
                  <input
                    id='email'
                    type='text'
                    className='bg-transparent border border-[#C2C7D6] py-2 px-3 rounded-md outline-none w-full'
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.email && touched.email && (
                    <p className='text-xs text-red'>
                      <ErrorMessage name='email' />
                    </p>
                  )}
                </div>
                <div className='mt-5 flex flex-col text-left w-full md:w-[70%] mx-auto'>
                  <label
                    htmlFor='password'
                    className='font-medium mb-1 text-xs'
                  >
                    Password
                  </label>
                  <div className='flex items-center justify-between bg-transparent border border-[#C2C7D6] py-2 px-1 pr-2 rounded-md '>
                    <input
                      id='password'
                      type={!showPassword ? "text" : "password"}
                      className='bg-transparent outline-none w-full md:text-sm'
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {!showPassword ? (
                      <button
                        className='w-[10%] p-1'
                        onClick={() => setShowPassword(true)}
                        type='button'
                      >
                        <EyeNoneIcon className='h-4 w-4 cursor-pointer' />
                      </button>
                    ) : (
                      <button
                        className='w-[10%] p-1'
                        onClick={() => setShowPassword(false)}
                        type='button'
                      >
                        <EyeOpenIcon className='h-4 w-4 cursor-pointer' />
                      </button>
                    )}
                  </div>
                  {errors.password && touched.password && (
                    <p className='text-xs text-red'>
                      <ErrorMessage name='password' />
                    </p>
                  )}
                </div>
                <div className='flex justify-end mt-5 w-full md:w-[70%] mx-auto'>
                  <p className='text-sm'>
                    <Link
                      to='/forgot-password'
                      className='cursor-pointer text-primaryGreen'
                    >
                      Forgot Password?
                    </Link>
                  </p>
                </div>
              </div>
              <div className='mt-8 w-full md:w-[50%] mx-auto'>
                <button
                  type='submit'
                  className='bg-primaryGreen flex justify-center mx-auto outline-none text-white w-[50%] py-2 rounded-md'
                  disabled={submitLoading}
                >
                  {submitLoading ? <Spinner /> : "Sign In"}
                </button>
                {/* <p className='mt-5'>
                  Don't have an account?{" "}
                  <Link to='/s' className='cursor-pointer text-primaryGreen'>
                    Sign Up
                  </Link>
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

export default Login;
