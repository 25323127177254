import React from "react";

const Errorpage = () => {
  return (
    <div>
      <div class='flex justify-center items-center h-screen bg-gray-200'>
        <div class='text-center'>
          <h1 class='text-4xl font-medium'>404</h1>
          <p class='text-xl font-medium m-6'>
            Sorry, There's an Error or the page you're looking for can't be
            found.
          </p>
          <a
            href='/dashboard'
            class='bg-blue-500 hover:bg-blue-600 text-primaryBlack py-2 px-4 rounded font-bold text-3xl'
          >
            Go to Dashboard
          </a>
        </div>
      </div>
    </div>
  );
};

export default Errorpage;
