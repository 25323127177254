import Dashboard from "../Pages/Dashboard";
import Orders from "../Pages/Orders";
import Accounts from "../Pages/Accounts";
import Riders from "../Pages/Riders";
import Users from "../Pages/Users";
import Audit from "../Pages/Audit";
import RiderProfile from "../Pages/Riders/RiderProfile";

export const roleBasedRoutes = {
  5: [
    // Customer Service
    {
      path: "/dashboard",
      element: Dashboard,
      label: "Dashboard",
      icon: "dashboard",
      allowedRoles: [5],
    },
    {
      path: "/orders",
      element: Orders,
      label: "Orders",
      icon: "orders",
      allowedRoles: [5],
    },
  ],
  3: [
    // Account
    {
      path: "/accounts",
      element: Accounts,
      label: "Accounts",
      icon: "accounts",
      allowedRoles: [3],
    },
    {
      path: "/orders",
      element: Orders,
      label: "Orders",
      icon: "orders",
      allowedRoles: [3],
    },
  ],
  4: [
    // Logistics
    {
      path: "/dashboard",
      element: Dashboard,
      label: "Dashboard",
      icon: "dashboard",
      allowedRoles: [4],
    },
    {
      path: "/riders",
      element: Riders,
      label: "Riders",
      icon: "riders",
      allowedRoles: [4],
    },
    {
      path: "/riders/profile/:id",
      element: RiderProfile,
      allowedRoles: [4],
    },
  ],
  2: [
    // Admin-Operations
    {
      path: "/dashboard",
      element: Dashboard,
      label: "Dashboard",
      icon: "dashboard",
      allowedRoles: [2],
    },
    {
      path: "/orders",
      element: Orders,
      label: "Orders",
      icon: "orders",
      allowedRoles: [2],
    },
    {
      path: "/riders",
      element: Riders,
      label: "Riders",
      icon: "riders",
      allowedRoles: [2],
    },
    {
      path: "/riders/profile/:id",
      element: RiderProfile,
      allowedRoles: [2],
    },
  ],
  1: [
    // Super Admin
    {
      path: "/dashboard",
      element: Dashboard,
      label: "Dashboard",
      icon: "dashboard",
      allowedRoles: [5, 4, 2, 1],
    },
    {
      path: "/orders",
      element: Orders,
      label: "Orders",
      icon: "orders",
      allowedRoles: [3, 5, 2, 1],
    },
    {
      path: "/accounts",
      element: Accounts,
      label: "Accounts",
      icon: "accounts",
      allowedRoles: [3, 1],
    },
    {
      path: "/riders",
      element: Riders,
      label: "Riders",
      icon: "riders",
      allowedRoles: [4, 2, 1],
    },
    {
      path: "/users",
      element: Users,
      label: "Users",
      icon: "users",
      allowedRoles: [1],
    },
    {
      path: "/audit",
      element: Audit,
      label: "Audit Logs",
      icon: "audit",
      allowedRoles: [1],
    },
    {
      path: "/riders/profile/:id",
      element: RiderProfile,
      allowedRoles: [4, 2, 1],
    },
  ],
};

export const routePerms = {
  "dashboard": [5, 4, 2, 1],
  "orders": [3, 5, 2, 1],
  "accounts": [3, 1],
  "riders": [4, 2, 1],
  "users": [1],
  "audit": [1],
  "riders/profile/:id": [4, 2, 1],
};

export const getRoutesForRole = (roleId) =>
  roleBasedRoutes[roleId] || roleBasedRoutes[1];
