import React, { useEffect, useState } from "react";
import DataTable from "../../Components/ui/dataTable";
import { Button } from "../../Components/ui/button";
import NavTabs from "../../Components/ui/tabs";
import { NavLink } from "react-router-dom";
import { useGetAllRidersQuery } from "./RiderApi";
import { format, parseISO } from "date-fns";
import { SkeletonTable } from "../../Components/ui/skeleton";
import { ProtectedRoutes } from "../../config/protectedRoutes";
import Pagination from "../../Components/ui/pagination";
import { images } from "../../Assets";
import { useDebouncedValue } from "../../Lib/hooks";

export const columns = [
  // {
  //   accessorKey: "no",
  //   header: "No",
  //   cell: ({ row }) => <div>{row.getValue("no")}</div>,
  // },
  {
    accessorKey: "name",
    header: "Name",
    cell: ({ row }) => (
      <div className='font-semibold'>{row.getValue("name")}</div>
    ),
  },
  {
    accessorKey: "createdAt",
    header: "Reg. Date",
    cell: ({ row }) => (
      <div className='capitalize'>
        {format(parseISO(row.getValue("createdAt")), "LLL dd, y")}
      </div>
    ),
  },
  {
    accessorKey: "phoneNumber",
    header: "Phone Number",
    cell: ({ row }) => (
      <div className='lowercase'>{row.getValue("phoneNumber")}</div>
    ),
  },
  {
    accessorKey: "email",
    header: "Email",
    cell: ({ row }) => <div className='lowercase'>{row.getValue("email")}</div>,
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: ({ row }) => {
      if (row.original?.status === "pending") {
        return (
          <Button asChild className='w-full' variant='pending'>
            <p>Pending</p>
          </Button>
        );
      }
      if (row.original?.status === "approved") {
        return (
          <Button asChild className='w-full' variant='successful'>
            <p>Active</p>
          </Button>
        );
      }
    },
  },
  {
    id: "action",
    cell: ({ row }) => {
      const details = row.original;
      return (
        <NavLink
          to={`/riders/profile/${details.id}`}
          className='whitespace-nowrap border border-primaryGreen text-primaryGreen p-2 rounded-md font-medium'
        >
          View Details
        </NavLink>
      );
    },
  },
];
const Riders = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebouncedValue(search, 2000);
  const { data, isLoading: loading } = useGetAllRidersQuery(
    { page, searchValue: debouncedSearch },
    {
      refectchOnFocus: true,
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
    }
  );
  const tabs = [
    { name: "Pending Approvals", id: "pending" },
    { name: "Active Riders", id: "active" },
    { name: "Declined Riders", id: "decline" },
  ];
  const [activeTab, setActiveTab] = useState("pending");
  const [newData, setNewData] = useState([]);

  useEffect(() => {
    try {
      if (loading === false) {
        switch (activeTab) {
          case "active":
            const approvedUsers = data?.data?.filter(
              (user) => user.status === "approved"
            );
            setNewData(approvedUsers);
            break;

          case "pending":
            const pendingUsers = data?.data?.filter(
              (user) => user.status === "pending"
            );
            setNewData(pendingUsers);
            break;

          case "decline":
            const declinedUsers = data?.data?.filter(
              (user) => user.status === "declined"
            );
            setNewData(declinedUsers);
            break;

          default:
            setNewData([]);
            break;
        }
      }
    } catch (error) {}
  }, [loading, activeTab, data?.data, page, debouncedSearch]);

  return (
    <div className='bg-white p-4'>
      <NavTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className='flex justify-between items-center my-4'>
        <div className='flex items-center bg-[#F5FEF1] w-[300px] p-1 rounded-sm'>
          <input
            id='firstName'
            className='bg-transparent w-full px-1 outline-none placeholder:text-sm'
            value={search}
            placeholder='Search'
            onChange={(e) => setSearch(e.target.value)}
          />
          <img src={images.search} className='h-5 w-5' />
        </div>
        {data?.data?.length > 0 && (
          <p className='text-bold text-[#667499]'>
            Total:{" "}
            <span className='text-primaryBlack'>{data?.data?.length}</span>
          </p>
        )}
      </div>
      <div className='max-h-[500px] overflow-hidden hover:overflow-auto  max-w-full rounded-md border'>
        <div className='w-full'>
          {loading ? (
            <SkeletonTable />
          ) : (
            <DataTable columns={columns} data={newData} />
          )}
        </div>
      </div>
      {!loading && (
        <Pagination
          page={page}
          limit={data?.limit ?? 0}
          total={data?.total ?? 0}
          setPage={setPage}
        />
      )}
    </div>
  );
};

export default ProtectedRoutes(Riders, [4, 2, 1]);
