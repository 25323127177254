import React from "react";
import { useSelector } from "react-redux";
import { useLocation, Navigate } from "react-router-dom";
import { getRoutesForRole } from "./roleBasedRoutes";

export const ProtectedRoutes = (WrappedComponent, allowedRoles) => {
  const ProtectedComponent = (props) => {
    const location = useLocation();
    const userRole = useSelector((state) => state.auth.data.roleId);
    const routeAccess = getRoutesForRole(userRole);
    const hasPermission = (roles, role) => {
      return roles.includes(role);
    };

    if (hasPermission(allowedRoles, userRole)) {
      console.log("User has permission, rendering component...");
      return <WrappedComponent {...props} />;
    } else {
      console.warn("User does not have permission, redirecting...");
      return (
        <Navigate
          to={routeAccess[0]?.path}
          state={{ from: location }}
          replace
        />
      );
    }
  };

  return (props) => <ProtectedComponent {...props} />;
};
