import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { images } from "../Assets";
import clsx from "clsx";
import { useScreenWidth } from "../Lib/hooks";
import { useDispatch } from "react-redux";
import { logout } from "../Lib/slices/authSlice";
import { getRoutesForRole } from "../config/roleBasedRoutes";
import { store } from "../store";
import riders from "../Assets/ridersIcon";
import accounts from "../Assets/accountIcon";
import users from "../Assets/userIcon";
import audit from "../Assets/settingsIcon";
import SignoutIcon from "../Assets/signoutIcon";
import dashboard from "../Assets/dashboardIcon";
import orders from "../Assets/orderIcon";

const Sidebar = ({ className, isSheet = false }) => {
  const small = useScreenWidth("max-width('768px')");
  const dispatch = useDispatch();
  const data = store.getState()?.auth;
  const routes = getRoutesForRole(data.data.roleId);
  const location = useLocation();

  const componentsMap = {
    riders,
    accounts,
    users,
    audit,
    dashboard,
    orders,
  };

  const DynamicComponent = ({ name, ...props }) => {
    const ComponentToRender = componentsMap[name];
    return ComponentToRender ? <ComponentToRender {...props} /> : null;
  };
  const sidebarClasses = isSheet
    ? "w-full h-full bg-white"
    : "hidden md:flex md:fixed md:inset-y-0 w-72 pt-3 bg-white z-20";
  if (!small) return null;

  return (
    <div className={clsx(sidebarClasses, className)}>
      <nav className='flex flex-col items-start w-[70%] mx-auto gap-4 pb-10'>
        <img
          src={images.nownow}
          className='w-[80%] h-auto mb-4'
          alt='nownow rider'
        />
        {routes.map((route) => {
          if (!route.label) return null;
          const Icon = images[route.icon];
          const pathname = location.pathname
            .split("/")
            .filter((segment) => segment)[0];
          return (
            <NavLink
              key={route.path}
              to={route.path}
              className={() =>
                clsx(
                  pathname === route.path.split("/")[1] && "bg-primaryGreen",
                  "flex items-center gap-2 w-full p-3 h-12 rounded-md hover:border border-primaryGreen"
                )
              }
              tabIndex={0}
              aria-label={route.label}
            >
              <DynamicComponent
                name={route.icon}
                color={
                  pathname === route.path.split("/")[1] ? "#fff" : undefined
                }
              />
              <p
                className={clsx(
                  pathname === route.path.split("/")[1]
                    ? "text-white"
                    : "text-primaryAsh",
                  "text-md font-medium"
                )}
              >
                {route.label}
              </p>
            </NavLink>
          );
        })}
        <button
          className='flex items-center gap-2 w-full p-3 h-12 rounded-md border border-primaryGreen mt-auto text-primaryAsh hover:bg-primaryGreen hover:text-white'
          onClick={() => dispatch(logout())}
          tabIndex={0}
          aria-label='Logout'
        >
          <SignoutIcon />
          <p className='text-md font-medium'>Logout</p>
        </button>
      </nav>
    </div>
  );
};

export default Sidebar;
