import React, { useMemo } from "react";
import { useGetAllAuditQuery } from "./AuditApi";
import { SkeletonTable } from "../../Components/ui/skeleton";
import DataTable from "../../Components/ui/dataTable";
import { format, parseISO } from "date-fns";
import { ProtectedRoutes } from "../../config/protectedRoutes";
const Audit = () => {
  const { data, isLoading } = useGetAllAuditQuery();
  const columns = [
    {
      accessorKey: "initiator",
      header: "Initiator",
      cell: ({ row }) => <div className=''>{row.getValue("initiator")}</div>,
    },
    {
      accessorKey: "dateAdded",
      header: "Date",
      cell: ({ row }) => (
        <div className=''>
          {format(parseISO(row.getValue("dateAdded")), "LLL dd, y hh:mm a")}
        </div>
      ),
    },
    {
      accessorKey: "action",
      header: "Action Type",
      cell: ({ row }) => <div className=''>{row.getValue("action")}</div>,
    },
    {
      accessorKey: "description",
      header: "Description",
      cell: ({ row }) => (
        <div className='w-full'>{row.getValue("description")}</div>
      ),
    },
  ];
  return (
    <div>
      <div className='my-4'>{/* <DatePickerWithRange /> */}</div>
      <div className='max-h-[600px] overflow-hidden hover:overflow-auto  max-w-full rounded-md border '>
        <div className='w-full'>
          {isLoading ? (
            <SkeletonTable />
          ) : (
            <DataTable columns={columns} data={data} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProtectedRoutes(Audit, [1]);
